import * as React from 'react';
import style from './WishlistApp.scss';
import stylePolyfill from './StylePolyfill.scss';
import {EmptyState} from '../EmptyState/EmptyState';
import {IPropsInjectedByViewerScript} from '../../types/app-types';
import {ISantaProps} from '@wix/native-components-infra/dist/es/src/types/types';
import {Pagination} from './Pagination/Pagination';
import {WishlistProductItem} from '../WishlistProductItem/WishlistProducItem';
import {LoadMore} from './LoadMore/LoadMore';
import {inlineStyleFix} from '../../../styles/inlineStyle';

import {ProductListGrid} from '../../../gallery/components/ProductList/ProductListGrid';
import {IProduct, LoadMoreType} from '../../../types/galleryTypes';
import classnames from 'classnames';
import {withGlobals} from '../../../globalPropsContext';

export interface IWishlistProps extends ISantaProps {
  globals: IPropsInjectedByViewerScript;
}

class WishlistAppComponent extends React.Component<IWishlistProps> {
  constructor(props) {
    super(props);
    this.reportAppLoaded = this.reportAppLoaded.bind(this);
  }

  public componentDidMount(): void {
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  private renderGrid() {
    const {gridType, products} = this.props.globals;

    return (
      <ProductListGrid
        gridType={gridType}
        products={products as IProduct[]}
        ProductItem={WishlistProductItem}
        isMobileOverride={this.props.globals.isMobile}
      />
    );
  }

  private renderHeader() {
    const {
      textsMap: {wishlistHeaderTitle, wishlistHeaderSubtitle},
    } = this.props.globals;
    return (
      <header className={style.header}>
        <h1 data-hook="title" className={style.title}>
          {wishlistHeaderTitle}
        </h1>
        <p data-hook="subtitle" className={style.subtitle}>
          {wishlistHeaderSubtitle}
        </p>
      </header>
    );
  }

  private renderEmptyState() {
    const {
      isMobile,
      textsMap: {emptyStateText, emptyStateLinkText},
      handleClickOnEmptyState,
      homePageUrl,
    } = this.props.globals;

    return (
      <EmptyState
        isMobile={isMobile}
        texts={{
          noProductsMessage: emptyStateText,
          link: emptyStateLinkText,
        }}
        dataHook={'empty-state'}
        handleClick={handleClickOnEmptyState}
        linkUrl={homePageUrl}
      />
    );
  }

  private renderProducts() {
    const {loadMoreType, hasMoreProducts} = this.props.globals;
    const Paginate = loadMoreType === LoadMoreType.PAGINATION ? Pagination : LoadMore;
    const shouldPagiate = loadMoreType === LoadMoreType.PAGINATION || hasMoreProducts === true;

    return (
      <>
        {this.renderGrid()}
        {shouldPagiate && <Paginate />}
      </>
    );
  }

  public render() {
    const {isLoaded, signature, isEmptyState, isRTL} = this.props.globals;
    /* istanbul ignore next: hard to test it */
    if (!isLoaded) {
      return null;
    }

    /* istanbul ignore next: hard to test it */
    const rootClass = classnames(style.root, stylePolyfill.root, {
      notCssPerBreakpoint: !this.props.host.usesCssPerBreakpoint,
    });

    return (
      <main className={rootClass} data-hook="app-wrapper" dir={isRTL ? 'rtl' : 'ltr'}>
        {this.renderHeader()}
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div data-hook="wishlist-content" data-signature={signature} className={style.content}>
          {isEmptyState ? this.renderEmptyState() : this.renderProducts()}
        </div>
      </main>
    );
  }

  private reportAppLoaded() {
    /* istanbul ignore next: hard to test it */
    this.props.globals.onAppLoaded && this.props.globals.onAppLoaded();
  }
}

export const WishlistApp = withGlobals(WishlistAppComponent);
